<template>
    <b-card no-body>
      <validation-observer v-slot="{ handleSubmit }" ref="VForm">
        <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
          <b-card-header>
            <b-row>
              <b-col lg="8">
                <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg=4>
                <b-form-group>
                    <template #label>Vehicle Category <strong class="text-danger">*</strong></template>
                    <v-select 
                      placeholer="Status"
                      v-model="row.mv_category_vehicle" 
                      :options="mrVehicleCategory"
                      :reduce="data => data.value" 
                      />
                    <VValidate name="Vehicle Category" v-model="row.mv_category_vehicle" :rules="mrValidation.mv_category_vehicle" />
                  </b-form-group>
              </b-col>
              <b-col lg=4>
                <b-form-group>
                    <template #label>Label <strong class="text-danger">*</strong></template>
                    <b-form-input v-model="row.mv_label" :formatter="$parent.formatUpper" placeholder="e.g. VARIO PUTIH"></b-form-input>
                    <VValidate name="Label" v-model="row.mv_label" :rules="mrValidation.mv_label" />
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <label>STNK Number <span class="text-danger mr5">*</span></label>
                  <b-form-input v-mask="'########'" v-model="row.mv_no_stnk" placeholder="e.g. 15320359"></b-form-input>
                  <VValidate name="STNK Number" v-model="row.mv_no_stnk" :rules="mrValidation.mv_no_stnk" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group>
                  <label>Registration Number (Plat No) <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_plat_no" :formatter="$parent.formatUpper" placeholder="e.g. B 6640 WEE" v-mask="'A #### AAA'"></b-form-input>
                  <VValidate name="Registration Number (Plat No)" v-model="row.mv_plat_no" :rules="mrValidation.mv_plat_no" />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <label>Owner <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_owner" placeholder="e.g. Supriyadi"></b-form-input>
                  <VValidate name="Owner" v-model="row.mv_owner" :rules="mrValidation.mv_owner" />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <label>Address <span class="text-danger mr5">*</span></label>
                  <b-form-textarea v-model="row.mv_address"></b-form-textarea>
                  <VValidate name="Address" v-model="row.mv_address" :rules="mrValidation.mv_address" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <label>Merk <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_merk" :formatter="$parent.formatUpper" placeholder="e.g. YAMAHA"></b-form-input>
                  <VValidate name="Merk" v-model="row.mv_merk" :rules="mrValidation.mv_merk" />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <label>Type <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_type" :formatter="$parent.formatUpper" placeholder="e.g. B65"></b-form-input>
                  <VValidate name="Type" v-model="row.mv_type" :rules="mrValidation.mv_type" />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <label>Category <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_category" :formatter="$parent.formatUpper" placeholder="e.g. SEPEDA MOTOR SOLO"></b-form-input>
                  <VValidate name="Category" v-model="row.mv_category" :rules="mrValidation.mv_category" />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <label>Model <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_model" :formatter="$parent.formatUpper" placeholder="e.g. SEPEDA MOTOR R2"></b-form-input>
                  <VValidate name="Model" v-model="row.mv_model" :rules="mrValidation.mv_model" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group>
                  <label>Manufacture Year <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_manufacture_year" placeholder="e.g. 2017"></b-form-input>
                  <VValidate name="Manufacture Year" v-model="row.mv_manufacture_year" :rules="mrValidation.mv_manufacture_year" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group>
                  <label>Cylinder Capacity <span class="text-danger mr5">*</span></label>
                  <b-input-group append="CC">
                    <b-form-input v-model="row.mv_cylinder_capacity" placeholder="e.g. 150"></b-form-input>
                  </b-input-group>
                  <VValidate name="Cylinder Capacity" v-model="row.mv_cylinder_capacity" :rules="mrValidation.mv_cylinder_capacity" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <label>Vehicle Identity Number <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_vehicle_identity_number" :formatter="$parent.formatUpper" placeholder="e.g. MH3SG4610HJ059870"></b-form-input>
                  <VValidate name="Vehicle Identity Number" v-model="row.mv_vehicle_identity_number" :rules="mrValidation.mv_vehicle_identity_number" />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <label>Engine Number <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_engine_number" :formatter="$parent.formatUpper" placeholder="e.g. G3J1E0086754"></b-form-input>
                  <VValidate name="Engine Number" v-model="row.mv_engine_number" :rules="mrValidation.mv_engine_number" />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <label>BPKB Number <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_no_bpkb" placeholder="e.g. NO51265877" :formatter="$parent.formatUpper"></b-form-input>
                  <VValidate name="BPKB Number" v-model="row.mv_no_bpkb" :rules="mrValidation.mv_no_bpkb" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="2">
                <b-form-group>
                  <label>Color <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_color" :formatter="$parent.formatUpper" placeholder="e.g. WHITE"></b-form-input>
                  <VValidate name="Color" v-model="row.mv_color" :rules="mrValidation.mv_color" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group>
                  <label>Type Fuel <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_type_fuel" :formatter="$parent.formatUpper" placeholder="e.g. BENSIN"></b-form-input>
                  <VValidate name="Type Fuel" v-model="row.mv_type_fuel" :rules="mrValidation.mv_type_fuel" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group>
                  <label>License Plate Color <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_plat_no_color" placeholder="e.g. BLACK" :formatter="$parent.formatUpper"></b-form-input>
                  <VValidate name="License Plate Color" v-model="row.mv_plat_no_color" :rules="mrValidation.mv_plat_no_color" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group>
                  <label>Registration Year <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_registration_year" placeholder="e.g. 2017"></b-form-input>
                  <VValidate name="Registration Year" v-model="row.mv_registration_year" :rules="mrValidation.mv_registration_year" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group>
                  <label>Location Code <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_location_code" :formatter="$parent.formatUpper" placeholder="03 (UPT CIPUTAT)"></b-form-input>
                  <VValidate name="Location Code" v-model="row.mv_location_code" :rules="mrValidation.mv_location_code" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group>
                  <label>Registration Queue Number <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mv_regist_queue_number" :formatter="$parent.formatUpper" placeholder="DU"></b-form-input>
                  <VValidate name="Registration Queue Number" v-model="row.mv_regist_queue_number" :rules="mrValidation.mv_regist_queue_number" />
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group>
                  <label>Expired Date <span class="text-danger mr5">*</span></label>
                  <date-picker type="date" value-type="format" format="YYYY-MM-DD"  prefix-class="crm" v-model="row.mv_stnk_expired_date" :disabled-date="$parent.beforeToday" />
                  <VValidate name="Expired Date" v-model="row.mv_stnk_expired_date" :rules="mrValidation.mv_stnk_expired_date" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg=4>
                <b-form-group class="">
                  <label>Status<span class="text-danger mr5">*</span></label>
                  <b-form-radio-group :options="Config.mr.StatusOptions" v-model="row[$parent.statusKey]" />
                  <VValidate name="Status" v-model="row[$parent.statusKey]" :rules="mrValidation[$parent.statusKey]" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col lg="12" class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">Cancel</button>
                <b-button type="submit" variant="info" class="btn-rounded">Submit</b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card>
</template>
<script>
 export default {
    props: {
      row: Object,
      mrVehicleCategory: Array,
      mrValidation: Object,
      Config: Object,
    },
    data(){
      return {
        mrType: [
          {label: "Staff", value: "S"},
          {label: "Partner", value: "P"}
        ]
      }
    },
    methods: {
      doSubmitForm() {
        this.$emit('click:doSubmit', 'VForm', this.$refs)
      },
    }
 }
</script>
